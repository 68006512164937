<template>
  <tr :class="`position-row position-${position.id}`">
    <td></td>
    <td class="text-left wrap-text">
      <p v-if="!isBO">{{ position.owner.viewName }}</p>
      <RouterLink :to="position.owner.viewUrl" v-if="!position.investor && isBO">{{
        position.owner.viewName
      }}</RouterLink>
      <p v-if="position.investor">{{ position.owner.viewName }}</p>
      <p class="nationalid-small">{{ formatNationalId(position.owner) }}</p>

      <div v-if="totals.error" class="warning-error-message">{{ getErrorText(totals.error) }}</div>
    </td>
    <td class="text-left wrap-text">
      <p v-if="!isBO">{{ position.investor && position.investor.viewName }}</p>
      <router-link :to="position.personUrl" v-if="position.investor && isBO">{{
        position.investor && position.investor.viewName
      }}</router-link>
      <p class="nationalid-small">{{ formatNationalId(position.investor) }}</p>
    </td>
    <td>{{ position.custodianName }}</td>
    <td v-if="isShare" class="text-left reservations">
      <div v-for="reservation in position.reservations" :key="reservation">{{ toReservationLabel(reservation) }}</div>
    </td>
    <td class="text-right">
      {{ shareNumbers }}
      <PositionAdditionalInformationOnRowItem :position="position" :useMargin="false" />
      <div v-if="totals.numberSerieError" class="warning-error-message">
        {{ getErrorText(totals.numberSerieError) }}
      </div>
    </td>
    <td class="text-right">{{ formatNumber(position.quantity) }}</td>
    <td v-if="isShare" class="text-right">{{ formatPercent(totals.quantityQuota) }}</td>
    <td v-if="isShare" class="text-right">{{ formatPercent(totals.votingPowerQuota) }}</td>

    <td class="q-table--col-auto-width more-menu test-more-menu" v-if="canEdit">
      <q-btn flat round color="grey-4" icon="more_vert" size="sm" :data-testid="`action-button-${position.id}`">
        <q-menu self="center left" anchor="center left" :offset="[70, 0]" class="a-tooltip shadow-1">
          <q-list>
            <q-item clickable v-close-popup @click="editPosition" :data-testid="`action-button-edit-${position.id}`">
              <q-item-section>Ändra</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="removePosition" v-if="!readonly">
              <q-item-section icon="delete">Ta bort</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </td>
  </tr>
</template>
<script lang="ts">
import * as _ from 'lodash';
import { Position, ShareRegister } from '@shared/web/models';
import { makeShareRegisterValidation } from '@shared/shareRegister/shareRegisterService';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toReservationLabel } from '@shared/common';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import RemovePositionModal from '@shared/views/editPositionModal/RemovePositionModal.vue';
import PositionAdditionalInformationOnRowItem from '@shared/components/PositionAdditionalInformationOnRowItem.vue';
import { formatNumber } from '@shared/utils';
import { store } from '@shared/web/store';
import { isBackOffice } from '@shared/web/utils';

@Component({ components: { PositionAdditionalInformationOnRowItem } })
export default class PositionRow extends Vue {
  @Prop() position: Position;
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: () => false }) readonly: boolean;

  isBO = isBackOffice();

  toReservationLabel(val) {
    return toReservationLabel(val);
  }

  get isShare() {
    return this.position.instrument.isShare;
  }

  get hasReservations() {
    return !_.isEmpty(this.position.reservations);
  }

  removePosition() {
    this.$q.dialog({
      component: RemovePositionModal,
      componentProps: {
        positionClass: Position,
        value: this.position,
        shareRegister: this.shareRegister,
      },
    });
  }

  editPosition() {
    this.$q.dialog({
      component: EditPositionModal,
      componentProps: {
        value: this.position,
        edit: true,
        label: 'ägare',
        shareRegister: this.shareRegister,
        readonly: this.readonly,
      },
    });
  }

  get totals() {
    const totals = makeShareRegisterValidation(this.shareRegister);
    return totals.forPosition(this.position);
  }

  get shareNumbers() {
    const { shareNumberFrom, shareNumberTo } = this.position;
    if (shareNumberFrom && shareNumberTo) {
      return formatNumber(shareNumberFrom) + '–' + formatNumber(shareNumberTo);
    }
    return null;
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false || isBackOffice();
  }
}
</script>
