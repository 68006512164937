import { Model } from '../base';
import { Activity, Address, Instrument, IssuerData, LegalEntity, Person, RoaringData, TermsAccepted } from '..';
import * as _ from 'lodash';
import { Structure } from '@shared/views/common/treeStructureList/treeStructureListTypes';
import { IEntity, IActiveTypes } from '@shared/models/modelTypes';

class ActiveTypes extends Model implements IActiveTypes {
  issuer?: boolean;
  kapsureIssuer?: boolean;
}
export default class Entity extends Model implements IEntity {
  nationalId: string = null;
  countryCode: string;
  autoUpdate: boolean;
  addresses: Array<Address> = [];
  issuerData: IssuerData = null;
  types: Array<string> = [];
  legalEntity: LegalEntity = null;
  person: Person = null;
  instruments: Array<Instrument> = [];
  isPerson: boolean;
  isGlobal: boolean = null;
  lastActivity: Activity;
  creator: Entity = null;
  roaringData: RoaringData = null;
  shareDataConsentedAt: Date = null;
  kapnetLicenseConsentedAt: Date = null;
  lastCommentAt: Date = null;
  termsAccepted: TermsAccepted = null;
  shareRegisterRequestedChangeMessage?: string;
  activeTypes?: ActiveTypes = new ActiveTypes();

  static define = () => ({
    addresses: {
      embeddedModel: [Address],
    },
    roaringData: {
      embeddedModel: RoaringData,
    },
    activeTypes: {
      embeddedModel: ActiveTypes,
    },
    legalEntity: {
      embeddedModel: LegalEntity,
      serializeChanges: true,
    },
    person: {
      embeddedModel: Person,
    },
    issuerData: {
      embeddedModel: IssuerData,
    },
    instruments: {
      embeddedModel: [Instrument],
      serialize: false,
    },
    lastActivity: {
      embeddedModel: Activity,
    },
    creator: {
      model: Entity,
    },
    termsAccepted: {
      embeddedModel: TermsAccepted,
    },
  });

  get viewUrl() {
    if (this.isPerson) {
      return '/person/view/' + this.id;
    } else {
      return '/legal-entity/view/' + this.id;
    }
  }

  get isLegalEntity() {
    return !this.isPerson;
  }

  get structure(): Structure {
    if (this.legalEntity.cachedStructure) {
      return this.legalEntity.cachedStructure;
    } else if (this.legalEntity.parentCompany) {
      return {
        title: this.legalEntity.parentCompany.viewName,
        children: [{ title: this.viewName }],
      };
    } else {
      return {
        title: this.viewName,
      };
    }
  }

  get viewName() {
    const { legalEntity, person } = this;
    if (legalEntity) {
      return legalEntity.shortName || legalEntity.name;
    }
    if (person) {
      return [person.firstName, person.surName].filter(value => value != null).join(' ');
    }
  }

  isType(type) {
    return _.includes(this.types, type);
  }

  get isSE() {
    return this.countryCode === 'SE';
  }

  get isInsuranceCompany() {
    return this.isType('insuranceCompany');
  }

  get isIssuer() {
    return this.isType('issuer') || this.isType('kapsureIssuer');
  }

  get isKapsureIssuer() {
    return this.isType('kapsureIssuer');
  }

  get isKapclearIssuer() {
    return this.isType('issuer');
  }

  get shares(): Array<Instrument> {
    return this.instruments.filter(instrument => instrument.isShare);
  }

  get missingInstruments() {
    return this.roaringData?.issuerData?.missingInstruments || [];
  }

  get sharesHasRoaringDiff(): boolean {
    return this.shares.findIndex((instrument: Instrument) => instrument.hasRoaringDiff === true) !== -1;
  }

  get issuerDataFromRoaring(): IssuerData {
    return this.roaringData?.issuerData;
  }

  get issuerDataTotalQuantityFromRoaring(): number {
    return this.issuerDataFromRoaring?.totalQuantity;
  }

  get issuerDataTotalCapitalFromRoaring(): number {
    return this.issuerDataFromRoaring?.totalCapital;
  }

  get roaringQuantityDiff(): number | null {
    if (!this.issuerDataTotalQuantityFromRoaring) {
      return null;
    }
    return Math.abs(this.issuerDataTotalQuantityFromRoaring - this.issuerData.totalQuantity);
  }

  get roaringCapitalDiff(): number | null {
    if (!this.issuerDataTotalCapitalFromRoaring) {
      return null;
    }
    return Math.abs(this.issuerDataTotalCapitalFromRoaring - this.issuerData.totalCapital);
  }

  get hasRoaringDiff(): boolean {
    return this.roaringQuantityDiff > 0 || this.roaringCapitalDiff > 0;
  }

  get baseUrl(): string {
    return `/${this.countryCode}/${this.nationalId}`;
  }
}
