import { Model } from '../base';
import { Entity, InstrumentValuation } from '@shared/web/models';
import * as _ from 'lodash';
import ShareData from './ShareData';
import RightsData from './RightsData';
import ShareDataRoaringData from './ShareDataRoaringData';
import { InstrumentCategory, InstrumentGroup, Products } from '@shared/models/types';
import { IInstrument } from '@shared/models/modelTypes';
import { instrumentIdTypes } from '@shared/common';

function emptyAsNull(val) {
  if (_.isEmpty(val)) {
    return null;
  }
  return val;
}

export default class Instrument extends Model implements IInstrument {
  name: string;
  shortName: string = null;
  entity: Entity;
  group: InstrumentGroup;
  category: InstrumentCategory;
  currencyCode: string;
  shareData: ShareData = null;
  rightsData: RightsData = null;
  ISIN: string = null;
  KapID: string = null;
  Did: string = null;
  Cic: string = null;
  roaringData: ShareDataRoaringData = null;
  autoUpdate: boolean;
  issueInstruction: string;
  products: Products[] = [];
  valuation: InstrumentValuation;
  reportCustodians: Entity[] = [];
  static define = () => ({
    entity: {
      model: Entity,
    },
    shareData: {
      embeddedModel: ShareData,
      serializeChanges: true,
    },
    rightsData: {
      embeddedModel: RightsData,
      serializeChanges: true,
    },
    KapID: {
      serialize: emptyAsNull,
    },
    ISIN: {
      serialize: emptyAsNull,
    },
    Did: {
      serialize: emptyAsNull,
    },
    Cic: {
      serialize: emptyAsNull,
    },
    valuation: {
      embeddedModel: InstrumentValuation,
    },
    reportCustodians: {
      model: [Entity],
    },
  });

  get viewUrl() {
    return this.id ? '/instrument/view/' + this.id : null;
  }

  get positionsUrl() {
    return `/position/?entity=${this.entity.id}&instrument=${this.id}`;
  }

  get transactionsUrl() {
    return `/transaction/?entity=${this.entity.id}&instrument=${this.id}`;
  }

  get issueInstructionUrl() {
    if (!this.issueInstruction) {
      return null;
    }

    return `/${this.entity.countryCode}/${this.entity.nationalId}/${this.uriLabel}/${this.issueInstruction}`;
  }

  get uriLabel() {
    switch (this.group) {
      case InstrumentGroup.WARRANT:
        return 'optionsprogram';
      case InstrumentGroup.CONVERTIBLE:
        return 'konvertibler';
      case InstrumentGroup.BOND:
        return 'obligationer';
      case InstrumentGroup.DEBENTURE:
        return 'vinstandelslan';
      case InstrumentGroup.SHAREHOLDER_CONTRIBUTION:
        return 'aktieägartillskott';
    }
  }

  get viewName() {
    return this.shortName || this.name;
  }

  get isShare() {
    return this.category === InstrumentCategory.SHA;
  }

  get isRight() {
    return this.category === InstrumentCategory.RIGHTS;
  }
  get isDebt() {
    return this.category === InstrumentCategory.DEBT;
  }

  get isConvertible() {
    return this.group === InstrumentGroup.CONVERTIBLE;
  }

  get isBond() {
    return this.group === InstrumentGroup.BOND;
  }

  get isDebenture() {
    return this.group === InstrumentGroup.DEBENTURE;
  }

  get isShareholderContribution() {
    return this.group === InstrumentGroup.SHAREHOLDER_CONTRIBUTION;
  }

  get instrumentId() {
    const { KapID, ISIN, Did } = this;
    return ISIN || KapID || Did;
  }

  get isValueDistribution() {
    return this.rightsData?.isValueDistribution();
  }

  get shareDataFromRoaring(): ShareData {
    return this.roaringData?.shareData;
  }

  get shareDataTotalQuantityFromRoaring(): number {
    return this.shareDataFromRoaring?.totalQuantity;
  }

  get shareDataTotalCapitalFromRoaring(): number {
    return this.shareDataFromRoaring?.totalCapital;
  }

  get shareDataVotingPowerFromRoaring(): number {
    return this.shareDataFromRoaring?.votingPower;
  }

  get quotaValueFromRoaring(): number {
    if (this.shareDataTotalCapitalFromRoaring && this.shareDataTotalQuantityFromRoaring) {
      return this.shareDataTotalCapitalFromRoaring / this.shareDataTotalQuantityFromRoaring;
    }
    return null;
  }

  get roaringQuantityDiff(): number | null {
    if (!this.shareDataTotalQuantityFromRoaring) {
      return null;
    }
    return Math.abs(this.shareDataTotalQuantityFromRoaring - this.shareData.totalQuantity);
  }

  get roaringCapitalDiff(): number | null {
    if (!this.shareDataTotalCapitalFromRoaring) {
      return null;
    }
    return Math.abs(this.shareDataTotalCapitalFromRoaring - this.shareData.totalCapital);
  }

  get quotaValueDiff(): number | null {
    if (!this.quotaValueFromRoaring) {
      return null;
    }
    return Math.abs(this.quotaValueFromRoaring - this.shareData.quotaValue);
  }

  get hasRoaringDiff(): boolean {
    return (
      this.shareDataTotalQuantityFromRoaring !== this.shareData.totalQuantity ||
      this.shareDataTotalCapitalFromRoaring !== this.shareData.totalCapital ||
      this.shareDataVotingPowerFromRoaring !== this.shareData.votingPower
    );
  }

  get isKapclearProduct() {
    return this.products.includes(Products.KAPCLEAR);
  }
  get isKapsureProduct() {
    return this.products.includes(Products.KAPSURE);
  }
  get activeIdTypes() {
    return instrumentIdTypes.filter(idType => !_.isEmpty(this[idType.value]));
  }
  get priceSuffix() {
    if (this.isValueDistribution) {
      return '%';
    } else {
      return this.currencyCode;
    }
  }

  initCategoryData() {
    if (this.entity && !this.currencyCode) {
      this.currencyCode = this.entity.issuerData?.currencyCode;
    }

    if (this.category === InstrumentCategory.SHA) {
      if (!this.shareData) {
        this.shareData = new ShareData();
      }
    }
    if (this.category === InstrumentCategory.RIGHTS) {
      if (!this.shareData) {
        this.shareData = new ShareData();
      }
      if (!this.rightsData) {
        this.rightsData = new RightsData();
      }
    }
    if (this.category === InstrumentCategory.DEBT) {
      if (!this.rightsData) {
        this.rightsData = new RightsData();
      }
    }
    return this;
  }
}
