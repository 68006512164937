import { Model } from '../base';
import { Instrument, Entity, Ownership } from '@shared/web/models';
import { IPosition } from '@shared/models/modelTypes';
export default class Position extends Model implements IPosition {
  quantity: number = null;
  amount?: number = null;
  acquisitionValue?: number;
  entity: Entity;
  instrument: Instrument;
  owner: Entity = null;
  investor: Entity = null;
  custodian: Entity;
  custodianAccountNumber: string = null;
  ownership: Ownership;
  pledgeOwner: Entity = null;
  insuranceNumber: string = null;
  reservations: Array<string> = [];
  certificateIssued = false;
  shareNumberFrom?: number = null;
  shareNumberTo?: number = null;
  settleFrom?: Date;

  static define = () => ({
    entity: {
      model: Entity,
    },
    instrument: {
      model: Instrument,
    },
    owner: {
      model: Entity,
    },
    investor: {
      model: Entity,
    },
    custodian: {
      model: Entity,
    },
    ownership: {
      model: Ownership,
    },
    pledgeOwner: {
      model: Entity,
    },
    settleFrom: {
      type: 'date',
    },
  });
  static baseUrl = '/api/position';

  get personUrl() {
    return '/person/view/' + this.investor?.id;
  }

  get transactionsUrl() {
    return this.instrument.transactionsUrl + '&owner=' + this.owner.id;
  }

  get absoluteInvestor() {
    return this.investor || this.owner;
  }

  get absoluteInvestorManager() {
    const investor = this.absoluteInvestor;
    if (investor && investor.isLegalEntity && investor.legalEntity.investorManager) {
      return investor.legalEntity.investorManager;
    }
    return investor;
  }

  get investorManager() {
    const investor = this.absoluteInvestor;
    if (investor && investor.isLegalEntity) {
      return investor.legalEntity.investorManager;
    }
    return null;
  }

  get custodianName() {
    return this.custodian?.viewName || 'Kapclear';
  }

  get pledgeIssued(): boolean {
    return !!this.pledgeOwner;
  }

  get currentValue() {
    if (!this.instrument.valuation) {
      return null;
    }
    return this.instrument.isValueDistribution
      ? this.instrument.valuation.priceSEK * this.amount
      : this.instrument.valuation.priceSEK * this.quantity;
  }
}
