<template>
  <q-markup-table flat class="standard-table PositionsTable" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left holder-name-th"></th>
        <SortableTH
          property="absoluteInvestorManager"
          :tableSettings="tableSettings"
          v-if="columns.absoluteInvestorManager"
          >Investeringsansvarig</SortableTH
        >
        <SortableTH property="instrument" :tableSettings="tableSettings" v-if="columns.instrument">Aktie</SortableTH>
        <SortableTH property="owner" :tableSettings="tableSettings" v-if="columns.owner">Ägare</SortableTH>
        <th class="text-left">Försäkringsägare</th>
        <th class="text-left">Förvar</th>
        <th class="text-left">Förbehåll</th>
        <template v-if="columns.shareNumbers">
          <SortableTH :alignRight="true" property="shareNumberFrom" :tableSettings="tableSettings"
            >Aktienummer</SortableTH
          >
        </template>
        <th class="text-right" v-if="columns.custodian">Förvar</th>
        <SortableTH :alignRight="true" property="quantity" :tableSettings="tableSettings">Antal</SortableTH>
        <th class="text-right col justify-bottom">
          <div class="row no-wrap items-center">
            <span>Kapitalandel</span>
            <a-info-btn>
              <p>
                Finns olika aktieslag rekommenderas att man kontrollerar bolagsordningen som visar hur vinster,
                utdelningar etc fördelas mellan aktieslagen. Kapitalandelen som visas, avser andelen av bolagets bundna
                aktiekapital
              </p>
            </a-info-btn>
          </div>
        </th>
        <th class="text-right">Röstandel</th>
        <th v-if="canEdit"></th>
      </tr>
    </thead>
    <tbody class="two-row">
      <template v-for="{ holder, positions } in positionGroups" :key="holder.id">
        <InstrumentRow :instrument="holder" :shareRegister="shareRegister" :readonly="readonly" />
        <template v-for="position in positions" :key="position.id">
          <PositionRow :position="position" :shareRegister="shareRegister" :readonly="readonly" />
        </template>
        <tr :key="'footer:' + holder.id" v-if="!readonly" class="narrow-row">
          <td></td>
          <td colspan="100">
            <q-btn
              color="secondary"
              dense
              rounded
              flat
              @click="addPosition(holder)"
              class="small test-add-owner"
              icon="add_circle"
              >Lägg till ägare</q-btn
            >
          </td>
        </tr>
      </template>
    </tbody>
  </q-markup-table>
</template>
<script lang="ts">
import { ShareRegister, Position } from '@shared/web/models';
import PositionRow from './PositionRow.vue';
import InstrumentRow from './InstrumentRow.vue';
import SortableTH from './SortableTH.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { store } from '@shared/web/store';
import { isBackOffice } from '@shared/web/utils';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';

@Component({
  components: { PositionRow, InstrumentRow, EditPositionModal, SortableTH },
})
export default class PositionsTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop() tableSettings: TableSettings;

  get positionGroups() {
    return this.shareRegister.getPositionGroups(this.tableSettings);
  }

  get sortedPositions() {
    return this.shareRegister.positions.sort((a, b) => a.shareNumberFrom - b.shareNumberFrom);
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false || isBackOffice();
  }

  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  addPosition(holder) {
    const entity = this.shareRegister.entity;
    const data = { instrument: holder, entity };

    this.$q.dialog({
      component: EditPositionModal,
      componentProps: {
        positionClass: Position,
        value: data,
        label: 'ägare',
        shareRegister: this.shareRegister,
      },
    });
  }

  get columns() {
    const { groupProperty, isInvestorRegister } = this.tableSettings;
    const { hasShareNumbers } = this.issuerData;
    return {
      owner: groupProperty !== 'owner' || isInvestorRegister,
      instrument: groupProperty !== 'instrument',
      absoluteInvestorManager: groupProperty !== 'absoluteInvestor' && isInvestorRegister,
      reservations: !isInvestorRegister,
      shareNumbers: hasShareNumbers && !isInvestorRegister,
      custodian: isInvestorRegister,
    };
  }
}
</script>
<style lang="scss">
.PositionsTable {
  position: relative;
}
</style>
