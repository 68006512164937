import { Model } from '../base/';
import { Entity, InstructionParty, Activity, Position } from '@shared/web/models';
import { InstructionState, TransactionType } from '@shared/models/types';
import { isCorporateEvent, isKapsureEvent, isTradeEvent } from '@shared/instruction/utils';
import TradeEvent from './TradeEvent';
import CorporateEvent from './CorporateEvent';
import { IInstruction } from '@shared/models/modelTypes';
import { KapsureCounterpart } from '@shared/models/types';
export default class Instruction extends Model implements IInstruction {
  destinations: Array<InstructionParty> = null;
  sources: Array<InstructionParty> = null;
  interimPositions: Array<Position> = null;
  quantity: number = null;
  price: number = null;
  entity: Entity = null;
  settleDate: Date = null;
  updatedAt: Date = null;
  registrationDate: Date;
  type: TransactionType;
  state: InstructionState;
  requestedChangeMessage: string = null;
  tradeEvent?: TradeEvent;
  corporateEvent?: CorporateEvent;
  hasFiles = false;
  files = [];
  lastActivity: Activity;
  warning = false;
  notes = '';
  skipIssuerDataValidation = false;
  counterpart?: KapsureCounterpart;

  static define = () => ({
    entity: {
      model: Entity,
    },

    destinations: {
      embeddedModel: [InstructionParty],
      serializeChanges: true,
    },
    sources: {
      embeddedModel: [InstructionParty],
      serializeChanges: true,
    },

    interimPositions: {
      embeddedModel: [Position],
      serialize: false,
    },

    settleDate: {
      type: 'date',
    },
    createdAt: {
      type: 'date',
    },
    updatedAt: {
      type: 'date',
    },

    registrationDate: {
      type: 'date',
    },
    tradeEvent: {
      embeddedModel: TradeEvent,
      serializeChanges: true,
    },
    corporateEvent: {
      embeddedModel: CorporateEvent,
      serializeChanges: true,
    },
    files: {
      serialize: false,
    },

    hasFiles: {
      serialize: false,
    },
    lastActivity: {
      embeddedModel: Activity,
    },
  });

  static baseUrl = '/api/instruction';

  get viewUrl() {
    if (isCorporateEvent(this.type)) {
      return '/corporate-event/' + this.id;
    }
    return '/instruction/view/' + this.id;
  }

  get webUrl() {
    return `${this.entity.baseUrl}/${this.uriLabel}/${this.id}`;
  }

  get isCorporateEvent() {
    return isCorporateEvent(this.type);
  }

  static isInterim(type) {
    return [TransactionType.RIGHTS_ISSUE, TransactionType.EXERCISE_WARRANT, TransactionType.ISSUE_SHARE].includes(type);
  }

  get isInterim() {
    return Instruction.isInterim(this.type);
  }

  get isTradeEvent() {
    return isTradeEvent(this.type);
  }

  get isKapsureEvent() {
    return isKapsureEvent(this.type);
  }

  get isRightsIssue() {
    return this.type === TransactionType.RIGHTS_ISSUE;
  }

  get calculatedState() {
    if (this.state === InstructionState.EXECUTED && this.settleDate > new Date()) {
      return InstructionState.EXECUTED_WAITING;
    }
    return this.state;
  }

  get executed() {
    return this.state === InstructionState.EXECUTED || this.state === InstructionState.EXECUTED_INTERIM;
  }

  get processTitle() {
    if (this.type === TransactionType.ISSUE_WARRANT) {
      return 'Emittera teckningsoption';
    }
    if (this.type === TransactionType.EXERCISE_WARRANT) {
      return 'Lös option';
    }
    if (this.type === TransactionType.RIGHTS_ISSUE) {
      return 'Nyemission bef aktieslag';
    }
    if (this.type === TransactionType.ISSUE_SHARE) {
      return 'Nyemission nytt aktieslag';
    }
    if (this.type === TransactionType.ISSUE_CONVERTIBLE) {
      return 'Emittera konvertibler';
    }
    if (this.type === TransactionType.EXERCISE_CONVERTIBLE) {
      return 'Stäng konvertibler';
    }
    if (this.type === TransactionType.ISSUE_BOND) {
      return 'Emittera obligationer';
    }
    if (this.type === TransactionType.EXERCISE_BOND) {
      return 'Stäng obligationer';
    }
    if (this.type === TransactionType.ISSUE_DEBENTURE) {
      return 'Emittera vinstandelslån';
    }
    if (this.type === TransactionType.EXERCISE_DEBENTURE) {
      return 'Stäng vinstandelslån';
    }
    if (this.type === TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION) {
      return 'Registrering av aktieägartillskott';
    }
  }

  get uriLabel() {
    switch (this.type) {
      case TransactionType.ISSUE_WARRANT:
      case TransactionType.EXERCISE_WARRANT:
        return 'optionsprogram';
      case TransactionType.ISSUE_CONVERTIBLE:
      case TransactionType.EXERCISE_CONVERTIBLE:
        return 'konvertibler';
      case TransactionType.ISSUE_BOND:
      case TransactionType.EXERCISE_BOND:
        return 'obligationer';
        return 'konvertibler';
      case TransactionType.ISSUE_DEBENTURE:
      case TransactionType.EXERCISE_DEBENTURE:
        return 'vinstandelslan';
      case TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION:
        return 'aktieagartillskott';
      case TransactionType.TRADE:
        return 'agarbyte';
    }
  }

  get isEditable() {
    return this.state === InstructionState.NEW || this.state === InstructionState.REQUEST_CHANGE;
  }

  get isDeleteable() {
    return [InstructionState.NEW, InstructionState.REQUEST_CHANGE, InstructionState.SUBMITTED].includes(this.state);
  }

  get isConvertible() {
    return this.type === TransactionType.ISSUE_CONVERTIBLE || this.type === TransactionType.EXERCISE_CONVERTIBLE;
  }

  get isBond() {
    return this.type === TransactionType.ISSUE_BOND || this.type === TransactionType.EXERCISE_BOND;
  }

  get isDebenture() {
    return this.type === TransactionType.ISSUE_DEBENTURE || this.type === TransactionType.EXERCISE_DEBENTURE;
  }

  get isWarrant() {
    return this.type === TransactionType.ISSUE_WARRANT || this.type === TransactionType.EXERCISE_WARRANT;
  }

  get isPledge(): boolean {
    return this.type === TransactionType.PLEDGE;
  }

  get isConvertPledge(): boolean {
    return this.type === TransactionType.EXERCISE_PLEDGE;
  }

  get isShareholderContribution() {
    return this.type === TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION;
  }

  get exerciseType() {
    switch (this.type) {
      case TransactionType.ISSUE_WARRANT:
        return TransactionType.EXERCISE_WARRANT;
      case TransactionType.ISSUE_CONVERTIBLE:
        return TransactionType.EXERCISE_CONVERTIBLE;
      case TransactionType.ISSUE_BOND:
        return TransactionType.EXERCISE_BOND;
      case TransactionType.ISSUE_DEBENTURE:
        return TransactionType.EXERCISE_DEBENTURE;
      default:
        throw new Error('Invalid instruction type');
    }
  }
}
